import * as React from 'react'
import Layout from '@/components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import Breadcrumbs from '@/components/Common/Breadcrumbs'
import AboutHome from '@/components/Home/About'
import CreativePage from '@/components/About/Creative'
import CounterArea from '@/components/Home/Counter'
import ProccesArea from '@/components/About/Procces'
import ChooseUs from '@/components/Home/ChooseUs'
import CallToAction from '@/components/Home/CallToAction'
import SkillArea from '@/components/About/Skill'
import TestimonialAbout from '@/components/About/Testimonial'
import TeamHome from '@/components/Home/Team'
// import FormAbout from '@/components/About/Form'

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" canonical={`${useLocation().host}`} />
      <Breadcrumbs title="About Us" />
      <AboutHome />
      <CreativePage />
      <CounterArea />
      <ProccesArea />
      <ChooseUs />
      <CallToAction />
      <SkillArea />
      {/*   
      <TestimonialAbout/>
      <TeamHome/> 
      <FormAbout/> 
       */}
    </Layout>
  )
}

export default AboutPage
