import React from 'react'

const SkillArea = () => {
  return (
    <section className="skill-section-ara margin-top-120 margin-bottom-80">
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="theme-section-title style-02">
              <h6 className="subtitle">Compassionate care</h6>
              <h4 className="title">Improving Patient Well-being with Empathy and Understanding</h4>
              <p>
                Perfect Care should prioritize providing compassionate care to each and every
                patient. The care team should have a strong commitment to improving the quality of
                life for those in their care and should approach each patient with empathy,
                kindness, and understanding. This can lead to better patient outcomes and a more
                positive care experience overall.
              </p>
            </div>
            <div className="Progress-wrap">
              <div className="main-content">
                <div id="example-1" className="examples">
                  <div className="cssProgress">
                    <div className="progress1">
                      <div className="cssProgress-bar" data-percent="90" 
                      style={{
                        width: '90%',
                        overflow: 'hidden'
                      }}>
                        <span className="cssProgress-label">90%</span>
                        <span className="category">Improved patient satisfaction</span>
                      </div>
                    </div>
                  </div>
                  <div className="cssProgress">
                    <div className="progress1">
                      <div className="cssProgress-bar cssProgress-info" data-percent="85"
                      style={{
                        width: '85%',
                        overflow: 'hidden'
                      }}>
                        <span className="cssProgress-label">85%</span>
                        <span className="category">Better clinical outcomes</span>
                      </div>
                    </div>
                  </div>
                  <div className="cssProgress">
                    <div className="progress1">
                      <div className="cssProgress-bar cssProgress-danger" data-percent="96" style={{
                        width: '96%',
                        overflow: 'hidden'
                      }}>
                        <span className="cssProgress-label">96%</span>
                        <span className="category">Increased patient trust</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="thumbnail">
              <img src="/img/sections/skill/compassionate.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SkillArea
