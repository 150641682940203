import React from 'react'

const ProccesArea = () => {
  return (
    <section className="proccess-section">
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="theme-section-title desktop-center">
              <h6 className="subtitle">Personalized Home Care Program</h6>
              <h4 className="title">Providing Perfect Home Health Care</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="process-single-item">
              <h4 className="title">Need Assessment</h4>
              <p>
                Our team will carefully assess the specific healthcare needs of the person in the
                home, taking into account factors such as age, pre-existing medical conditions, and
                any physical limitations.
              </p>
              <div className="line-shape"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-6">
            <div className="process-single-item style-01">
              <h4 className="title">Care Team Selection</h4>
              <p>
                We will select a highly skilled and experienced healthcare team, including nurses,
                physical therapists, occupational therapists, and other in-home caregivers as
                needed.
              </p>
              <div className="line-shape"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="process-single-item style-02">
              <h4 className="title">Assessment and Planning</h4>
              <p>
                We will conduct a thorough assessment of the person and their home environment to
                determine necessary care and treatments. We will develop a personalized care plan
                that includes regular treatment and follow-up schedules.
              </p>
              <div className="line-shape"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-6">
            <div className="process-single-item style-03">
              <h4 className="title">Implementation and Monitoring</h4>
              <p>
                We will implement the care plan, ensuring that all healthcare professionals are
                aware of the person's needs and goals. We will regularly monitor the person's
                progress and make adjustments to the care plan as needed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProccesArea
