import React from 'react'

const CreativePage = () => {
  return (
    <section className="creative-section margin-top-90">
      <img src="/img/shapes/shape-01.png" className="shape-01" alt="" />
      <div className="container nav-container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="thumbnail">
              <img src="/img/sections/creative/about-us.png" alt="img" />
              {/*               <img src="/img/sections/creative/small-01.png" alt="" className="small" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="content">
              <span>
                <a href="tel:+13058233561">
                  <i className="fas fa-phone"></i>+1(305)823-3561
                </a>
              </span>
              <h4 className="title">About Perfect Care</h4>
              <p>
                Perfect care is an organization that provides home care services, skilled nursing
                care, physical therapy, occupational therapy, speech therapy, and personal care by
                home health aides. We are a for-profit AHCA licensed Medicare certified home health
                agency. Our services are available to residents of Miami-Dade and Monroe County.
              </p>
              <div className="btn-wrapper">
                <div className="btn-wrap">
                  <a href="/services" className="boxed-btn round-btn">
                    View Services
                  </a>
                </div>
                <div className="author-details">
                  {/*     <div className="thumb">
                    <img src="/img/sections/team/ceo.png" alt="img" />
                  </div> */}
                  {/*<div className="author-content">*/}
                  {/*  <h5 className="author-name">Orosmaidys Rodriguez</h5>*/}
                  {/*  <p className="designation">Administrator, CPC, CRC,CPMA</p>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreativePage
